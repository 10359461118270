import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/flexible.js'
import '../theme/index.css'

// 文件上传组件
import FileUpload from "@/components/FileUpload"

// 图片上传组件
import ImageUpload from "@/components/ImageUpload"

Vue.config.productionTip = false
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')