import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    choseDialog: false,
    userObj: {},
    // uploadUrl:
    //   "https://t-ys1910.gh199.cn/api-consumer/consumerSystem/login/upload",
    // groupUrl:
    //   "https://t-ys1910.gh199.cn/api-consumer/consumerSystem/crew/importExtra",
    // uploadUrl: "https://www.f1910.com/api-consumer/consumerSystem/login/upload",
    // groupUrl:
    //   "https://www.f1910.com/api-consumer/consumerSystem/crew/importExtra",
    uploadUrl: process.env.VUE_APP_BASE_API + "/consumerSystem/login/upload",
    groupUrl: process.env.VUE_APP_BASE_API + "/consumerSystem/crew/importExtra",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
