<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less">
::-webkit-scrollbar {
  /* 滚动条整体样式 */
  // width: .0313rem;
  // 隐藏滚动条
  width: 0;
  /* 宽高分别对应横竖滚动条的尺寸 */
  background: rgb(235, 238, 245);
  border-radius: .0521rem;
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  /* 滚动条里的小方块 */
  border-radius: .0156rem;
  background: #fff;
  height: .625rem;
}
::-webkit-scrollbar-track {
  /* 滚动条里面的轨道 */
  -webkit-box-shadow: inset 0 0 .026rem rgba(0, 0, 0, 0.2);
  border-radius: .0521rem;
  background: rgba(0, 0, 0, 0.1);
}
body {
  background-color: #bbb;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}

#app {
  width: 10rem;
  height: 100%;
  // margin: 0 auto;
  background-color: white;
}
</style>
