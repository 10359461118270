import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index/index.vue"),
      },
      {
        path: "/scene",
        name: "scene",
        component: () => import("../views/scene/index.vue"),
      },
      {
        path: "/scene/sceneDetail",
        name: "sceneDetail",
        component: () => import("../views/scene/sceneDetail/index.vue"),
      },
      {
        path: "/serve",
        name: "serve",
        component: () => import("../views/serve/index.vue"),
      },
      {
        path: "/serve/serveDetail",
        name: "serveDetail",
        component: () => import("../views/serve/serveDetail/index.vue"),
      },
      {
        path: "/serve/shop",
        name: "shop",
        component: () => import("../views/serve/shop/index.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/contact/index.vue"),
      },
      {
        path: "/nbStyle",
        name: "nbStyle",
        component: () => import("../views/nbStyle/index.vue"),
      },
      {
        path: "/outreach",
        name: "outreach",
        component: () => import("../views/outreach/index.vue"),
      },
      {
        path: "/dynamic",
        name: "dynamic",
        component: () => import("../views/dynamic/index.vue"),
      },
      {
        path: "/dynamicDetail",
        name: "dynamicDetail",
        component: () => import("../views/dynamic/dynamicDetail/index.vue"),
      },
      {
        path: "/dynamic/dynamicActive",
        name: "dynamicActive",
        component: () => import("../views/dynamicActive/index.vue"),
      },
      {
        path: "/dynamic/dynamicActiveDetail",
        name: "dynamicActiveDetail",
        component: () =>
          import("../views/dynamicActive/dynamicActiveDetail/index.vue"),
      },
      {
        path: "/policy",
        name: "policy",
        component: () => import("../views/policy/index.vue"),
      },
      {
        path: "/policyDetail",
        name: "policyDetail",
        component: () => import("../views/policy/policyDetail/index.vue"),
      },
      {
        path: "/peopleCenter",
        name: "peopleCenter",
        redirect: "/peopleCenter/myCrew",
        component: () => import("../views/peopleCenter/index.vue"),
        children: [
          {
            path: "/peopleCenter/myCrew",
            name: "peopleCenter/myCrew",
            component: () => import("../views/peopleCenter/myCrew/index.vue"),
          },
          {
            path: "/peopleCenter/workOrder",
            name: "peopleCenter/workOrder",
            component: () =>
              import("../views/peopleCenter/workOrder/index.vue"),
          },
          {
            path: "/peopleCenter/myOrder",
            name: "peopleCenter/myOrder",
            component: () => import("../views/peopleCenter/myOrder/index.vue"),
          },
          {
            path: "/peopleCenter/myCrewDetail",
            name: "peopleCenter/myCrewDetail",
            component: () =>
              import("../views/peopleCenter/myCrew/detail/index.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
